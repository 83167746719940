/**
 * @author Irakli Kandelaki
 * @note
 * @summary Detect if an element is in viewport.
 */

/** IMPORT COMPOSABLES */
import { useMainStore } from "@/store/main/index";

export const isInView = (el: Element, offset: number = 1): boolean => {
    const mainStore = useMainStore();
    return (
        el?.getBoundingClientRect()?.top <= mainStore.windowHeight * offset ||
        el?.getBoundingClientRect()?.bottom <= mainStore.windowHeight
    );
};
